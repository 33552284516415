<template>
<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                Adventure starts here 🚀
            </b-card-title>
            <b-card-text class="mb-2">
                Sign up today and start sending messages
            </b-card-text>
            <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>

            <validation-observer ref="registerForm">
                <b-form class="auth-register-form mt-2">
                    <!-- username -->
                    
                    <!-- phone -->
                    <b-form-group label="Phone Number" label-for="phone">
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-form-select v-model="countryCode" :options="countryCodeOptions" />
                            </b-input-group-prepend>
                            <cleave id="phone" v-model="phone" class="form-control" :raw="false" :options="options.phone" placeholder="725560980" />
                        </b-input-group>

                    </b-form-group>

                    <!-- name -->
                    <b-form-group label="Fullnames" label-for="register-name">
                        <validation-provider #default="{ errors }" name="Name" rules="required">
                            <b-form-input id="register-name" v-model="fullname" name="register-username" :state="errors.length > 0 ? false:null" placeholder="John Doe" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    

                    <!-- email -->
                    <b-form-group label="Your Email" label-for="register-email">
                        <validation-provider #default="{ errors }" name="Email" rules="required|email">
                            <b-form-input id="register-email" v-model="userEmail" name="register-email" :state="errors.length > 0 ? false:null" placeholder="john@example.com" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Company Email" label-for="register-email">
                        <validation-provider #default="{ errors }" name="Email" rules="required|email">
                            <b-form-input id="register-email" v-model="clientEmail" name="register-email" :state="errors.length > 0 ? false:null" placeholder="info@company.com" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!--Company Name-->
                    <b-form-group label="Company Name" label-for="register-username">
                        <validation-provider #default="{ errors }" name="companyName" rules="required">
                            <b-form-input id="register-username" v-model="companyName" name="register-username" :state="errors.length > 0 ? false:null" placeholder="Company X" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group>
                        <b-form-checkbox id="register-privacy-policy" v-model="terms" name="checkbox-1">
                            I agree to
                            <b-link>privacy policy & terms</b-link>
                        </b-form-checkbox>
                    </b-form-group>

                    <b-button variant="primary" block type="submit" @click.prevent="validationForm" :disabled="!terms">
                        <b-spinner small v-show="isLoading" />
                        <span id="signUp"> Sign Up </span>
                    </b-button>
                </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
                <span>Already have an Account? </span>
                <b-link :to="{name:'login'}">
                    <span>&nbsp;Login Now</span>
                </b-link>
            </b-card-text>

            <!-- <div class="divider my-2">
                    <div class="divider-text">
                        or
                    </div>
                </div>
                <div class="auth-footer-btn d-flex justify-content-center">

                    <b-button variant="google" href="javascript:void(0)">
                        <feather-icon icon="MailIcon" />
                    </b-button>
                    <b-button variant="github" href="javascript:void(0)">
                        <feather-icon icon="GithubIcon" />
                    </b-button>
                </div> -->
        </b-card>
        <!-- /Login v1 -->
    </div>
</div>
</template>

<script>
/* eslint-disable global-require */
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCard,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    BAlert,
    BInputGroupPrepend
} from 'bootstrap-vue'
import {
    required,
    email
} from '@validations'
import {
    togglePasswordVisibility
} from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery'
import {
    heightFade
} from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import router from '@/router'
import 'cleave.js/dist/addons/cleave-phone.us'
export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BCard,
        BImg,
        BForm,
        BButton,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        BAlert,
        Cleave,
        BInputGroupPrepend
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            fullname:"",
            fname: '',
            lname: '',
            username: '',
            status: '',
            companyName: '',
            password: '',
            phone: '',
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required: null,
            email: '',
            clientEmail: '',
            isLoading: false,
            fullPage: true,
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            countryCode: '254',
            errorMessage: "",
            utm:"liden",
            options: {
                phone: {
                    phone: true,
                    phoneRegionCode: 'KE',
                },
            },
            countryCodeOptions: [{
                    value: '254',
                    text: 'KE (+254)'
                },
                {
                    value: '233',
                    text: 'GH (+233)'
                },
            ],
            terms: false
        }
    },
    directives: {
        heightFade,
        Ripple,
    },
    mounted() {
        let vm = this
        console.log("utm ".localStorage.getItem('utm_source'))
        vm.getCountries()
       
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        validationForm() {
            let vm = this
            this.$refs.registerForm.validate().then(success => {
                if (success) {
                    vm.isLoading = true
                    $('#signUp').html(' Please Wait ...');
                    var names = vm.fullname.split(" ");
                    vm.fname = names[0]
                    vm.lname = names[1]
                    vm.username = vm.fname+""+vm.lname;

                    $.post({
                        url: store.state.rootUrl + 'account/v1/signup',
                        post: "POST",
                        // headers: {
                        //     "Content-Type": "application/json",
                        //     "Authorization": "Bearer " + store.state.accessToken
                        // },
                        data: JSON.stringify({
                            service_key: store.state.servicesKey,
                            user_name: vm.username,
                            email_address: vm.userEmail,
                            client_email: vm.clientEmail,
                            mobile_number: vm.phone,
                            country_code: vm.countryCode,
                            first_name: vm.fname,
                            middle_name: vm.lname,
                            client_name: vm.companyName,
                            channel_id: 4,
                            source: localStorage.getItem('utm_source')//store.state.utm_source
                        }),
                        success: function (response, status, jQxhr) {
                            vm.isLoading = false
                            $('#signUp').html('Sign Up');
                            if (response.data.code != 200) {
                                vm.dismissCountDown = vm.dismissSecs
                                vm.showDismissibleAlert = true
                                vm.errorMessage = response.data.message

                            } else {
                                vm.$cookies.set("username", vm.phone,"900s");
                                vm.$cookies.set("country_code", vm.countryCode, "900s");
                                vm.$router.push({
                                    name: 'verification'
                                });
                            }

                        },
                        error: function (jQxhr, status, error) {
                            vm.isLoading = false
                            vm.dismissCountDown = vm.dismissSecs
                            $('#signUp').html('Sign Up');
                            var errorMessage = jQxhr.responseJSON.data.message;
                            var statustext = jQxhr.responseJSON.statusDescription;
                            vm.showDismissibleAlert = true;
                            if (!statustext) {
                                vm.errorMessage = "An error occurred. Try again later.";
                            } else {
                                vm.errorMessage = statustext + " " + errorMessage;
                            }
                        }
                    });
                }
            })

        },
        getCountries() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/countries',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + store.state.accessToken
                },
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.countries = []
                    } else {
                        let result = response.data.data;

                        for (const [key, value] of Object.entries(result)) {
                            console.log(`${key}: ${value[0]}`);
                            var country = {
                                value: `${key}`,
                                text: `${value[0]}` + " (+" + `${key}` + ")"

                            }
                            vm.countryCodeOptions.push(country)
                        }

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.countries = []
                }
            });
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
